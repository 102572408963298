window._ = require('lodash');

import Axios from 'axios';
import Flowbite from 'flowbite/dist/flowbite.js';
import Selectr from 'mobius1-selectr';
import Toasted from 'toastedjs';
import Swal from 'sweetalert2';
import Base from './other/app.js';

try {
    window.axios = Axios;
    window.Flowbite = Flowbite;
    window.Selectr = Selectr;
    window.Toasted = Toasted;
    window.Swal = Swal;

    window.Base = new Base();
}
catch (e) { }

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


"use strict";

class Base {
    /** Método que aplica al elegir una región (lugar de residencia)
        div: contenedor
        region: selector
        type: 'country' ó 'child'
     */
    changeRegion(div, region, type) {
        /** Al elegir una región del selector */
        region.addEventListener('change', (event) => {
            /** Sí el tipo es 'country' y existen selectores hijos se eliminan */
            if (type === 'country' && document.body.contains(document.querySelector('.div_children'))) {
                document.querySelectorAll('.div_children').forEach(item => {
                    item.remove();
                })
            }

            /** Sí el tipo es 'child' y existen selectores después del recién creado */
            if (type === 'child' && document.body.contains(div.nextElementSibling)) {
                let next = div.nextElementSibling;

                /** Sí el elemento es un selector con la clase 'div_children' se elimina */
                if (next.classList.contains('div_children')) {
                    next.remove();
                }
            }

            /** Spinner indicativo */
            let spinner = document.querySelector('#spinner');

            /** Muestra el spinner mientras procesa la petición */
            spinner.classList.remove('hidden');

            /** Valor de la región elegida */
            let region_value = region.value;

            /** Ruta que retorna la cantidad de hijos de la región */
            let url = `/regions/count/${region_value}`

            axios({
                body: JSON.stringify({ region_value }),
                method: 'put',
                url: url
            }).then((response) => {
                /** Sí no tiene hijos cambia los atributos 'id' y 'name' a 'region_id' */
                if (response.data == 0) {
                    region.id = 'region_id';
                    region.name = 'region_id';

                /** Si tíene hijos cambia los atributos 'id' y 'name' a 'parent_id' */
                } else {
                    region.id = 'parent_id';
                    region.name = 'parent_id';

                    /** Método que crea el selector con los hijos de la región */
                    this.showRegions(div, region_value);
                }

                /** Oculta el spinner cuando termina la petición */
                spinner.classList.add('hidden');
            }).catch((error) => {
                let message;

                switch (error.response.status) {
                    case 400:
                        message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 403:
                        message = '<p class="m-0">Acceso denegado.</p>';
                        break;

                    case 404:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 405:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;

                    case 500:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 504:
                        message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 509:
                        message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    default:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;
                }

                this.showToasted("alive", "error", message);
            });
        })
    }

    /** Método que aplica al elegir una región (lugar de nacimiento)
        div: contenedor
        region: selector
        type: 'country' ó 'child'
     */
    changeRegionTwo(div, region, type) {
        /** Al elegir una región del selector */
        region.addEventListener('change', (event) => {
            /** Sí el tipo es 'country' y existen selectores hijos se eliminan */
            if (type === 'country' && document.body.contains(document.querySelector('.div_children_two'))) {
                document.querySelectorAll('.div_children_two').forEach(item => {
                    item.remove();
                })
            }

            /** Sí el tipo es 'child' y existen selectores después del recién creado */
            if (type === 'child' && document.body.contains(div.nextElementSibling)) {
                let next = div.nextElementSibling;

                /** Sí el elemento es un selector con la clase 'div_children_two' se elimina */
                if (next.classList.contains('div_children_two')) {
                    next.remove();
                }
            }

            /** Spinner indicativo */
            let spinner = document.querySelector('#spinnerTwo');

            /** Muestra el spinner mientras procesa la petición */
            spinner.classList.remove('hidden');

            /** Valor de la región elegida */
            let region_value = region.value;

            /** Ruta que retorna la cantidad de hijos de la región */
            let url = `/regions/count/${region_value}`

            axios({
                body: JSON.stringify({ region_value }),
                method: 'put',
                url: url
            }).then((response) => {
                /** Sí no tiene hijos cambia los atributos 'id' y 'name' a 'region_birth_id' */
                if (response.data == 0) {
                    region.id = 'region_birth_id';
                    region.name = 'region_birth_id';

                /** Si tíene hijos cambia los atributos 'id' y 'name' a 'parent_two_id' */
                } else {
                    region.id = 'parent_two_id';
                    region.name = 'parent_two_id';

                    /** Método que crea el selector con los hijos de la región */
                    this.showRegionsTwo(div, region_value);
                }

                /** Oculta el spinner cuando termina la petición */
                spinner.classList.add('hidden');
            }).catch((error) => {
                let message;

                switch (error.response.status) {
                    case 400:
                        message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 403:
                        message = '<p class="m-0">Acceso denegado.</p>';
                        break;

                    case 404:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 405:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;

                    case 500:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 504:
                        message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 509:
                        message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    default:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;
                }

                this.showToasted("alive", "error", message);
            });
        })
    }

    /** Método que aplica al chequear el control de '¿Su profesión requiere Tarjeta Profesional?' */
    checkRequireCard() {
        if (
            document.body.contains(document.querySelector('#div_expedition_card_date'))
            && document.body.contains(document.querySelector('#div_file'))
            && document.body.contains(document.querySelector('#div_require_card'))
            && document.body.contains(document.querySelector('#expedition_card_date'))
            && document.body.contains(document.querySelector('#file'))
            && document.body.contains(document.querySelector('#require_card'))
        ) {
            let div_expedition_card_date = document.querySelector("#div_expedition_card_date");
            let div_file = document.querySelector("#div_file");
            let expedition_card_date = document.querySelector("#expedition_card_date");
            let file = document.querySelector("#file");
            let require_card = document.querySelector("#require_card");

            /** Comprobación inicial apenas carga la página */
            if (require_card.checked == false) {
                /** Oculta el control de fecha de expedición */
                div_expedition_card_date.classList.add('hidden');
                div_expedition_card_date.classList.remove('md:grid');
                expedition_card_date.required = false;
                expedition_card_date.value = null;

                /** Oculta el control de archivo tarjeta profesional */
                div_file.classList.add('hidden');
                div_file.classList.remove('md:grid');
            } else {
                /** Muestra el control de fecha de expedición */
                div_expedition_card_date.classList.remove('hidden');
                div_expedition_card_date.classList.add('md:grid');
                expedition_card_date.required = true;

                /** Muestra el control de archivo tarjeta profesional */
                div_file.classList.remove('hidden');
                div_file.classList.add('md:grid');
            }

            /** Comprobación dinámica que se dispara cuando se interactua con el control */
            require_card.addEventListener('change', (event) => {
                if (event.target.checked == false) {
                    /** Oculta el control de fecha de expedición */
                    div_expedition_card_date.classList.add('hidden');
                    div_expedition_card_date.classList.remove('md:grid');
                    expedition_card_date.required = false;
                    expedition_card_date.value = null;
                    file.value = null;

                    /** Oculta el control de archivo tarjeta profesional */
                    div_file.classList.add('hidden');
                    div_file.classList.remove('md:grid');
                } else {
                    /** Muestra el control de fecha de expedición */
                    div_expedition_card_date.classList.remove('hidden');
                    div_expedition_card_date.classList.add('md:grid');
                    expedition_card_date.required = true;

                    /** Muestra el control de archivo tarjeta profesional */
                    div_file.classList.remove('hidden');
                    div_file.classList.add('md:grid');
                }
            })
        }
    }

    /** Método que aplica al tener un selector de país (lugar de residencia) */
    chooseCountry() {
        if (document.body.contains(document.querySelector('#div_parent'))) {
            let region = document.querySelector("#region_id");
            let div = document.querySelector('#div_parent');

            try {
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir un país */
            this.changeRegion(div, region, 'country')
        }
    }

    /** Método que aplica al tener un selector de país (lugar de nacimiento) */
    chooseCountryTwo() {
        if (document.body.contains(document.querySelector('#div_parent_two'))) {
            let region = document.querySelector("#region_birth_id");
            let div = document.querySelector('#div_parent_two');

            try {
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir un país */
            this.changeRegionTwo(div, region, 'country')
        }
    }

    /** Método que aplica al tener un selector de país (En el formulario de experiencia académica) */
    chooseCountryThree() {
        if (
            document.body.contains(document.querySelector('#div_academic_title_id'))
            && document.body.contains(document.querySelector('#div_external_academic_title'))
            && document.body.contains(document.querySelector('#div_external_institution'))
            && document.body.contains(document.querySelector('#div_institution'))
            && document.body.contains(document.querySelector('#div_is_validated'))
            && document.body.contains(document.querySelector('#external_academic_title'))
            && document.body.contains(document.querySelector('#external_institution'))
            && document.body.contains(document.querySelector('#institution_id'))
            && document.body.contains(document.querySelector('#label_file'))
            && document.body.contains(document.querySelector('#region_id'))
        ) {
            let div_academic_title = document.querySelector("#div_academic_title_id");
            let div_external_academic_title = document.querySelector("#div_external_academic_title");
            let div_external_institution = document.querySelector("#div_external_institution");
            let div_institution = document.querySelector("#div_institution");
            let div_is_validated = document.querySelector("#div_is_validated");
            let external_academic_title = document.querySelector("#external_academic_title");
            let external_institution = document.querySelector("#external_institution");
            let institution = document.querySelector("#institution_id");
            let label_file = document.querySelector('#label_file');
            let region = document.querySelector("#region_id");

            try {
                new Selectr(institution);
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Al elegir un país del selector */
            region.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Sí se elige 'Colombia' */
                if (text == '--COLOMBIA--') {
                    /** Cambia el texto que acompaña al control de tipo file */
                    label_file.innerText = 'Diploma, acta de grado o certificación';

                    /** Muestra el selector de institutos */
                    div_institution.classList.remove('hidden');
                    div_institution.classList.add('md:grid');
                    institution.required = true;

                    /** Muestra el selector de programas */
                    div_academic_title.classList.remove('hidden');
                    div_academic_title.classList.add('md:grid');

                    /** Oculta el campo de texto para el instituto */
                    div_external_institution.classList.remove('md:grid');
                    div_external_institution.classList.add('hidden');
                    external_institution.required = false;

                    /** Oculta el campo de texto para el programa */
                    div_external_academic_title.classList.remove('md:grid');
                    div_external_academic_title.classList.add('hidden');
                    external_academic_title.required = false;

                    /** Oculta el campo de convalidación */
                    div_is_validated.classList.add('hidden');

                /** Sí se elige otro país */
                } else {
                    /** Cambia el texto que acompaña al control de tipo file */
                    label_file.innerText = 'Convalidación';

                    /** Oculta el selector de institutos */
                    div_institution.classList.add('hidden');
                    div_institution.classList.remove('md:grid');
                    institution.required = false;

                    /** Oculta el selector de programas */
                    div_academic_title.classList.add('hidden');
                    div_academic_title.classList.remove('md:grid');

                    /** Muestra el campo de texto para el instituto */
                    div_external_institution.classList.remove('hidden');
                    div_external_institution.classList.add('md:grid');
                    external_institution.required = true;

                    /** Muestra el campo de texto para el programa */
                    div_external_academic_title.classList.remove('hidden');
                    div_external_academic_title.classList.add('md:grid');
                    external_academic_title.required = true;

                    /** Muestra el campo de convalidación */
                    div_is_validated.classList.remove('hidden');
                }
            })
        }
    }

    /** Método que aplica al seleccionar una universidad/instituto desde el formulario de estudios */
    chooseInstitution() {
        if (
            document.body.contains(document.querySelector('#div_academic_title_id'))
            && document.body.contains(document.querySelector('#div_institution'))
            && document.body.contains(document.querySelector('#institution_id'))
        ) {
            let div_academic_title = document.querySelector("#div_academic_title_id");
            let institution = document.querySelector("#institution_id");

            institution.addEventListener('change', (event) => {
                let institution_value = institution.value;
                let url = `/academic_titles/get/${institution_value}`;

                axios({
                    body: JSON.stringify({ institution_value }),
                    method: 'put',
                    url: url
                }).then((response) => {
                    div_academic_title.classList.remove('hidden');
                    div_academic_title.innerHTML = response.data;

                    let academic_title = document.querySelector("#academic_title_id");

                    try {
                        new Selectr(academic_title);
                    } catch (error) {
                        console.log(error);
                    }
                }).catch((error) => {
                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar los títulos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se pueden mostrar los títulos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se pueden mostrar los títulos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se pueden mostrar los títulos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar los títulos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar los títulos, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se pueden mostrar los títulos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.showToasted("alive", "error", message);
                });
            })
        }
    }

    /** Método que aplica al eliminar un estudio desde el perfil de un usuario con rol 'Interesado' */
    destroyDegree() {
        document.querySelectorAll('.destroyDegree').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta experiencia académica?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/profile/degree/${id}`
                        }).then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/home`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la experiencia académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la experiencia académica porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la experiencia académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la experiencia académica porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la experiencia académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la experiencia académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la experiencia académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>La experiencia académica seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un trabajo desde el perfil de un usuario con rol 'Interesado' */
    destroyJob() {
        document.querySelectorAll('.destroyJob').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta experiencia laboral?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/profile/job/${id}`
                        }).then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/home`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>La experiencia laboral seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un mensaje */
    destroyNote() {
        document.querySelectorAll('.destroyNote').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este mensaje?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/notes/${id}`
                        }).then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.reload(), 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el mensaje, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el mensaje porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el mensaje porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el mensaje porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el mensaje, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el mensaje, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el mensaje porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El mensaje seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una etiqueta */
    destroyTag() {
        document.querySelectorAll('.destroyTag').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta etiqueta?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/tags/${id}`
                        }).then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.reload(), 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la etiqueta, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la etiqueta porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la etiqueta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la etiqueta porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la etiqueta, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la etiqueta, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la etiqueta porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>La etiqueta seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un usuario */
    destroyUser() {
        document.querySelectorAll('.destroyUser').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este usuario?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/users/${id}`
                        }).then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/users`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El usuario seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    /** Método que deshabilita el botón de descargar consolidado por 3 minutos */
    disableDownload() {
        if (
            document.body.contains(document.querySelector('#btn_download')) &&
            document.body.contains(document.querySelector('#form_consolidated')) &&
            document.body.contains(document.querySelector('#text_minute'))
        ) {
            let btn_download = document.querySelector("#btn_download");
            let form_consolidated = document.querySelector("#form_consolidated");
            let text_minute = document.querySelector("#text_minute");

            btn_download.addEventListener('click', event => {
                form_consolidated.submit();
                event.target.disabled = true;
                event.target.classList.remove("bg-blue-500");
                event.target.classList.add("cursor-not-allowed", "bg-blue-300");
                text_minute.classList.remove("hidden");

                setTimeout((error) => {
                    event.target.disabled = false;
                    event.target.classList.remove("cursor-not-allowed", "bg-blue-300");
                    event.target.classList.add("bg-blue-500");
                    text_minute.classList.add("hidden");
                }, 180000);
            })
        }
    }

    disableForm() {
        document.querySelectorAll('.form-main').forEach(form => {
            form.addEventListener('submit', event => {
                document.querySelectorAll('.btn-form').forEach(button => {
                    button.disabled = true;
                })
            })
        })
    }

    /** Método que crea el selector con los hijos de la región (lugar de residencia) */
    showRegions(div_parent, region) {
        /** Ruta que devuelve los hijos de una región */
        let url = `/regions/get/${region}`

        axios({
            body: JSON.stringify({ region }),
            method: 'put',
            url: url
        }).then((response) => {
            /** Contenedor principal */
            let div_region = document.createElement('div');

            /** Label del selector */
            let label = document.createElement('label');

            /** Contenedor secundario */
            let div_select = document.createElement('div');

            /** Selector */
            let select = document.createElement('select');

            /** Opción por defecto */
            let option_default = document.createElement('option');

            /** Asigna las clases para el contenedor principal */
            div_region.classList.add("items-center", "w-full", "gap-3", "mb-1", "md:grid", "md:grid-cols-3", "div_children");

            /** Asigna las clases para el label */
            label.classList.add("mb-0", "font-bold", "text-slate-600");

            /** Asigna el texto para el label */
            label.innerText = "";

            /** Asigna las clases para el contenedor secundario */
            div_select.classList.add("md:col-span-2");

            /** Asigna las clases para el selector */
            select.classList.add("w-full", "px-3", "py-2", "leading-tight", "bg-white", "border", "border-slate-300", "rounded", "appearance-none", "focus:shadow-outline", "focus:bg-slate-200/75", "placeholder:text-gray-600");

            /** Asigna el título para el selector */
            select.title = "Elija la región";

            /** Asigna el atributo 'required' para el selector */
            select.required = "required";

            /** Asigna el atributo 'id' para el selector */
            select.id = 'region_id';

            /** Asigna el atributo 'name' para el selector */
            select.name = 'region_id';

            /** Asigna el texto para la opción por defecto */
            option_default.innerText = "--Seleccione--";

            /** Asigna el atributo 'value' para la opción por defecto */
            option_default.value = "";

            /** Asigna el atributo 'selected' para la opción por defecto */
            option_default.selected = "selected";

            /** Asigna el atributo 'disabled' para la opción por defecto */
            option_default.disabled = "disabled";

            /** Coloca el contenedor después del selector de país */
            div_parent.parentNode.insertBefore(div_region, div_parent.nextSibling);

            /** Coloca el label dentro del contenedor principal */
            div_region.appendChild(label);

            /** Coloca el contenedor secundario dentro del contenedor principal */
            div_region.appendChild(div_select);

            /** Coloca el selector dentro del contenedor secundario */
            div_select.appendChild(select);

            /** Coloca la opción por defecto dentro del selector */
            select.appendChild(option_default);

            /** Recorre el array de regiones hijas */
            response.data.forEach((value) => {
                /** Crea una opción por cada región, asignandole el texto y valor correspondiente */
                let option = document.createElement('option');
                option.innerText = value.name;
                option.value = value.id;

                /** Coloca la opción dentro del selector */
                select.appendChild(option);
            });

            /** Convierte el selector en un campo de búsqueda-selección */
            try {
                new Selectr(select);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir una región */
            this.changeRegion(div_region, select, 'child')
        }).catch((error) => {
            let message;

            switch (error.response.status) {
                case 400:
                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 403:
                    message = '<p class="m-0">Acceso denegado.</p>';
                    break;

                case 404:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 405:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;

                case 500:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 504:
                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 509:
                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                default:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;
                }

            this.showToasted("alive", "error", message);
        });
    }

    /** Método que crea el selector con los hijos de la región (lugar de nacimiento) */
    showRegionsTwo(div_parent, region) {
        /** Ruta que devuelve los hijos de una región */
        let url = `/regions/get/${region}`

        axios({
            body: JSON.stringify({ region }),
            method: 'put',
            url: url
        }).then((response) => {
            /** Contenedor principal */
            let div_region = document.createElement('div');

            /** Label del selector */
            let label = document.createElement('label');

            /** Contenedor secundario */
            let div_select = document.createElement('div');

            /** Selector */
            let select = document.createElement('select');

            /** Opción por defecto */
            let option_default = document.createElement('option');

            /** Asigna las clases para el contenedor principal */
            div_region.classList.add("items-center", "w-full", "gap-3", "mb-1", "md:grid", "md:grid-cols-3", "div_children_two");

            /** Asigna las clases para el label */
            label.classList.add("mb-0", "font-bold", "text-slate-600");

            /** Asigna el texto para el label */
            label.innerText = "";

            /** Asigna las clases para el contenedor secundario */
            div_select.classList.add("md:col-span-2");

            /** Asigna las clases para el selector */
            select.classList.add("w-full", "px-3", "py-2", "leading-tight", "bg-white", "border", "border-slate-300", "rounded", "appearance-none", "focus:shadow-outline", "focus:bg-slate-200/75", "placeholder:text-gray-600");

            /** Asigna el título para el selector */
            select.title = "Elija la región";

            /** Asigna el atributo 'required' para el selector */
            select.required = "required";

            /** Asigna el atributo 'id' para el selector */
            select.id = 'region_birth_id';

            /** Asigna el atributo 'name' para el selector */
            select.name = 'region_birth_id';

            /** Asigna el texto para la opción por defecto */
            option_default.innerText = "--Seleccione--";

            /** Asigna el atributo 'value' para la opción por defecto */
            option_default.value = "";

            /** Asigna el atributo 'selected' para la opción por defecto */
            option_default.selected = "selected";

            /** Asigna el atributo 'disabled' para la opción por defecto */
            option_default.disabled = "disabled";

            /** Coloca el contenedor después del selector de país */
            div_parent.parentNode.insertBefore(div_region, div_parent.nextSibling);

            /** Coloca el label dentro del contenedor principal */
            div_region.appendChild(label);

            /** Coloca el contenedor secundario dentro del contenedor principal */
            div_region.appendChild(div_select);

            /** Coloca el selector dentro del contenedor secundario */
            div_select.appendChild(select);

            /** Coloca la opción por defecto dentro del selector */
            select.appendChild(option_default);

            /** Recorre el array de regiones hijas */
            response.data.forEach((value) => {
                /** Crea una opción por cada región, asignandole el texto y valor correspondiente */
                let option = document.createElement('option');
                option.innerText = value.name;
                option.value = value.id;

                /** Coloca la opción dentro del selector */
                select.appendChild(option);
            });

            /** Convierte el selector en un campo de búsqueda-selección */
            try {
                new Selectr(select);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir una región */
            this.changeRegionTwo(div_region, select, 'child')
        }).catch((error) => {
            let message;

            switch (error.response.status) {
                case 400:
                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 403:
                    message = '<p class="m-0">Acceso denegado.</p>';
                    break;

                case 404:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 405:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;

                case 500:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 504:
                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 509:
                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                default:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;
                }

            this.showToasted("alive", "error", message);
        });
    }

    /** Método que aplica a elementos tipo 'select' */
    selectConfig() {
        let config = {
            customClass: "custom-style",
            selectedValue: "#2ecc71"
        }

        document.querySelectorAll('.selectr').forEach(item => {
            let id = item.getAttribute("id");

            try {
                new Selectr(document.getElementById(id));
            } catch (error) {
                console.log(error);
            }
        })

        document.querySelectorAll('.selectr-record').forEach(item => {
            let id = item.getAttribute("id");

            try {
                new Selectr(document.getElementById(id), config);
            } catch (error) {
                console.log(error);
            }
        })
    }

    /** Alerta informativa grande */
    showSwal(confirmButtonColor, html, icon, title) {
        Swal.fire({
            backdrop: 'rgba(0, 0, 0, 0.95)',
            background: '#FFFFFF',
            confirmButtonColor: confirmButtonColor,
            customClass: "md:w-1/2 w-11/12",
            html: html,
            icon: icon,
            title: title
        })
    }

    /** Alerta de confirmación grande */
    showSwalConfirm(cancelButtonText, confirmButtonColor, confirmButtonText, html, icon, title) {
        return Swal.fire({
            cancelButtonText: cancelButtonText,
            confirmButtonColor: confirmButtonColor,
            confirmButtonText: confirmButtonText,
            customClass: "md:w-1/2 w-11/12",
            focusConfirm: false,
            html: html,
            icon: icon,
            showCancelButton: true,
            showCloseButton: true,
            title: title
        })
    }

    /** Alerta informativa pequeña */
    showToasted(theme, type, message) {
        const toasted = new Toasted({
            duration: 5000,
            position: "bottom-left",
            theme: theme,
            type: type
        })

        toasted.show(message);
    }
}

window.onload = () => {
    let base = new Base();

    base.checkRequireCard();
    base.chooseCountry();
    base.chooseCountryThree();
    base.chooseCountryTwo();
    base.chooseInstitution();
    base.destroyDegree();
    base.destroyJob();
    base.destroyNote();
    base.destroyTag();
    base.destroyUser();
    base.disableDownload();
    base.disableForm();
    base.selectConfig();
};
